import { ref, Ref } from 'vue'
import CourseResponse = models.server.api.international.course.CourseResponse
import AggregatedCourses = models.server.api.international.predictive.AggregatedCourses
import ApiQueryBody = models.server.api.international.ApiQueryBody
import ApiResponse = models.server.api.international.ApiResponse
import Course = models.server.api.international.course.Course
import { delay } from '~/utils/helpers'
// emums
enum ApiSearch {
  Cache = 'is-cache-filters',
  Search = 'is-search-courses-v1.0',
  Suggest = 'is-suggest-courses-v1.0',
  Url = '/api/international/course'
}

// summary
// this composable uses a object to store filters for ease of modifying crud operations
// this object is mapped to an array to be used for dumb components like chips
// the filter is mapped to a data shape usable by the api. It was decided to seperate this logic so that if
// changes happen to api it wont affect the components
// the two key api calls are to get courses or course summary results for search only purposes

// interfaces of the composable int prefix for international

interface IntFilter {
  [key: string]: { value: Array<string>; last?: boolean } // eg qualification?: { value: Array<string>; last?: boolean }
}
interface IntClientFilter {
  field: string
  value: string
  last?: true
}

// constants for setup so we have base objects that are used to populate dropdowns
const courseTypes = {
  'TAFE NSW Courses': false,
  'TAFE NSW Vocational pathways': false,
  'TAFE NSW Degree pathways': false,
  'University degree pathways': false
}
const qualifications = {
  'Bachelor degree': false,
  'Associate degree': false,
  'Advanced diploma': false,
  Diploma: false,
  'Certificate II': false,
  'Certificate IV': false,
  'Certificate III': false
}

// we export the logic as a composable
export const useIntCourseSearch = () => {
  // reactive variables we can use in our components in alphabetical order

  // aggregation used to show users how many results they could have
  const aggregation: Ref<number> = ref(0)

  // bodyRequest is the initial body request setup for the api
  const bodyRequest: Ref<ApiQueryBody> = ref({
    id: ApiSearch.Search,
    params: {
      from: 0,
      size: 50
    }
  })

  // bodyRequest is the initial body request setup for the api
  const bodyRequestSummary: Ref<ApiQueryBody> = ref({
    id: ApiSearch.Suggest,
    params: {
      from: 0,
      size: 50
    }
  })

  // breadcrums is used for course search page
  const breadcrumbs = ref([
    {
      text: 'International',
      to: '/international'
    },
    {
      text: 'Course search',
      to: '/international/course-search'
    }
  ])

  // courses is full detail and used for cards
  const courses: Ref<Course[]> = ref([])

  // coursesSummary is used for searchbar where we show the user a list of options
  // it is summarised to the name and the id
  const coursesSummary: Ref<{ name: string; id: string }[]> = ref([])

  // courseCampus is a single location a user selects
  const courseCampus = ref('Any location')

  // campuses are the returned campuses from the api
  const campuses: Ref<string[]> = ref([])

  // errorCoursesApi
  const errorCoursesApi: Ref<string | undefined> = ref()

  // this is the most important variable in the composable.
  // it creates a tree of all the filters, to simplify all crud operations
  const filtersTree: Ref<{ [key: string]: { [key: string]: boolean } }> = ref({
    location: {},
    subjectArea: {},
    qualification: qualifications,
    courseType: courseTypes
  })

  const loadingCourses = ref(true)

  // queryString is when the user types in a value
  const queryString: Ref<string> = ref('')

  // retries is used for apiCalls
  const retries: Ref<number> = ref(3)

  // computed values we can use in our components

  // mappedFilters is used in our components and takes our core filters tree and maps it to an array.
  // See the international filter chips test page
  const mappedFilters = computed(() => {
    const tree = filtersTree.value
    const filtersArray: Array<IntClientFilter> = []
    Object.keys(tree).forEach((field: string) => {
      Object.keys(tree[field]).forEach((value) => {
        if (tree[field][value]) filtersArray.push({ field, value })
      })
    })
    return filtersArray
  })

  // this maps the filter tree to the correct shape for the api to consume.
  // this has been done seperately so that changes to the api can be accommodated
  // without making changes to the components or composables

  const filtersForApi = computed(() => {
    const tree = filtersTree.value
    const fieldObj: IntFilter = {}
    const filterArray: IntFilter[] = []

    // first step is combine all the field values into a IntFilter shape
    Object.keys(tree).forEach((field: string) => {
      Object.keys(tree[field]).forEach((value) => {
        if (tree[field][value]) {
          if (!fieldObj[field]) fieldObj[field] = { value: [], last: false }
          fieldObj[field].value.push(value)
        }
      })
    })

    // we set up a lastField to index the final item in the filterArray
    let lastField

    // secondly we take the consolidated fields, ie location and we push that to an array
    Object.keys(fieldObj).forEach((field: string) => {
      const consolidated: IntFilter = {}
      consolidated[field] = fieldObj[field]
      filterArray.push(consolidated)
      lastField = field
    })

    // finally we take the last item from the array and using the lastField, set last = true for elastic parsing
    if (lastField) filterArray[filterArray.length - 1][lastField].last = true
    return filterArray
  })

  // reachedEnd checks whether we have loaded all the courses. Currently used for infinite scroll
  const reachedEnd = computed(() => {
    if (!aggregation.value) return false
    return aggregation.value <= courses.value.length
  })

  // methods in alphabetical order

  // apiCall local only method, includes a retry method
  const apiCall = async () => {
    errorCoursesApi.value = undefined
    if (filtersForApi.value)
      bodyRequest.value.params.filters = filtersForApi.value
    let apiResponse
    while (retries.value > 0) {
      try {
        // api call
        apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
          method: 'POST',
          body: bodyRequest.value
        })
        if (apiResponse?.hits?.hits?.length == 0) throw new Error('no hits')
        break
      } catch (e) {
        retries.value = retries.value - 1
        await delay(2000)
      }
    }
    retries.value = 3
    if (!apiResponse) errorCoursesApi.value = 'service unavailable'
    return apiResponse
  }

  // apiMap to convert api response to our needed shape. Is seperated as mapping may change, and may potentially add a mapping selection process.
  const apiMap = (apiResponse: ApiResponse | undefined) => {
    const data = apiResponse?.hits?.hits as CourseResponse[]
    const mapped =
      data?.map((r) => ({
        ...r._source,
        id: r._id
      })) || []
    const aggregate = apiResponse?.hits?.total?.value || 0
    return { mapped, aggregate }
  }

  // fetchCourses accumulates responses from the api and adds them to existing results
  const fetchCourses = async (reset: boolean) => {
    loadingCourses.value = true
    if (reset) courses.value = []
    updatePagination(20, courses.value.length)
    const apiResponse = await apiCall()
    const { mapped, aggregate } = apiMap(apiResponse)
    aggregation.value = aggregate
    loadingCourses.value = false
    for (const course of mapped) {
      courses.value.push(course)
    }
  }

  // fetchCampuses is a api call that gets a list of campuses to fill a dropdown
  const fetchCampuses = async () => {
    bodyRequest.value.id = ApiSearch.Cache
    try {
      const apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
        method: 'POST',
        body: bodyRequest.value
      })
      const data = apiResponse.aggregations as AggregatedCourses | undefined
      const mapped =
        data?.location?.buckets
          .map((r) => r.key)
          .filter((key) => key.length > 1) || []
      campuses.value = mapped
    } catch (e) {
    } finally {
      bodyRequest.value.id = ApiSearch.Search
    }
  }

  // key difference here is the fetchCoursesSummary is id and name only. used for a searchbar
  const fetchCoursesSummary = async () => {
    try {
      const apiResponse = await $fetch<ApiResponse>(ApiSearch.Url, {
        method: 'POST',
        body: bodyRequestSummary.value
      })

      const data = apiResponse?.hits?.hits as CourseResponse[]
      const mapped =
        data?.map((r) => ({
          name: r._source.title,
          id: r._id
        })) || []
      coursesSummary.value = mapped
    } catch (e) {}
  }

  // removeFilter is a crud operation to update the filter tree to false. The reason we dont delete is because some
  // components need the field name to label, such as a multi select component
  const removeFilter = async ({
    field,
    value
  }: {
    field: string
    value: string
  }) => {
    filtersTree.value[field][value] = false
    await fetchCourses(true)
  }

  // setCourseFilterObject is a crud operation to update the filterTree, which in turn automatically updates the mapped filters.
  // after updating the filtertree it updates the courses by fetching the data. Could potentially call seperately
  const setCourseFilterObject = async (
    field: 'location' | 'subjectArea' | 'qualification' | 'courseType',
    event: { [value: string]: boolean } | undefined
  ) => {
    const tree = filtersTree.value
    if (event) {
      Object.keys(event).forEach((value) => {
        if (!tree[field]) tree[field] = {}
        tree[field][value] = event[value]
      })
    } else {
      tree[field] = {}
    }
    await fetchCourses(true)
  }

  // updateCourseFilter may require updating, this is for the searchbar summary search
  const updateCourseFilter = async (filterKey: 'campus', input: string) => {
    if (filterKey == 'campus') courseCampus.value = input
    bodyRequestSummary.value.params[filterKey] = input
    await fetchCourses(true)
  }

  // updateCourseQuery allows for user to type in a search and filter results.
  // it also fetches the courses. could be done seperately.
  const updateCourseQuery = async (input?: string) => {
    bodyRequest.value.params.query_string = `${input}`
    queryString.value = input || ''
    await fetchCourses(true)
  }

  // updateCoursesSummaryQuery is same as updateCourseQuery but is just the name and id.
  // to be used for search bar, where detail is not required.
  // currently also fetches the courses with detail due to course search page
  const updateCourseSummaryQuery = async (input: string | undefined) => {
    // clear the summary results if no search input
    if (!input || input === '') {
      coursesSummary.value = []
      return
    }

    bodyRequestSummary.value.params.query_string = `${input}`
    queryString.value = input
    await fetchCoursesSummary()
  }

  // updatePagination to handle infinite scroll
  const updatePagination = (size: number, from: number) => {
    bodyRequest.value.params.from = from
    bodyRequest.value.params.size = size
  }

  return {
    // reactive variables
    aggregation,
    bodyRequest,
    breadcrumbs,
    campuses,
    courseCampus,
    courses,
    coursesSummary,
    errorCoursesApi,
    filtersTree,
    loadingCourses,
    queryString,

    // computed
    mappedFilters,
    reachedEnd,

    // methods
    fetchCampuses,
    fetchCoursesSummary,
    fetchCourses,
    removeFilter,
    setCourseFilterObject,
    updateCourseQuery,
    updateCourseFilter,
    updateCourseSummaryQuery,
    updatePagination
  }
}
